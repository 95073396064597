import React from 'react';
import MemeTokenWebsite from './MemeTokenWebsite';

function App() {
  return (
    <div>
      <MemeTokenWebsite />
    </div>
  );
}

export default App;