import React, { useEffect, useState } from 'react';
import { 
  Copy, 
  MessageCircle, 
  ExternalLink, 
  Moon, 
  Sun, 
  ThumbsUp, 
  Repeat, 
  MessageSquare, 
  Image as ImageIcon 
} from 'lucide-react';
import { init, useQuery } from "@airstack/airstack-react";

// Initialize Airstack
init(process.env.REACT_APP_AIRSTACK_API_KEY || "YOUR_AIRSTACK_API_KEY");

const CASTS_QUERY = `
  query GetCastsByUsername {
    FarcasterCasts(
      input: {blockchain: ALL, filter: {castedBy: {_eq: "fc_fname:ghffb47yii2rteey"}}, limit: 50}
    ) {
      Cast {
        castedAtTimestamp
        url
        text
        fid
        numberOfLikes
        numberOfRecasts
        numberOfReplies
        embeds
      }
    }
  }
`;

const MemeTokenWebsite = () => {
  const [showCopied, setShowCopied] = useState(false);
  const [price, setPrice] = useState(null);
  const [isDark, setIsDark] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [priceError, setPriceError] = useState(null);

  // Fetch casts using Airstack
  const { data: castsData, loading: castsLoading, error: castsError } = useQuery(CASTS_QUERY);
  const casts = castsData?.FarcasterCasts?.Cast || [];

  const contractAddress = "0x354d6890caA31a5E28B6059D46781F40880786a6";

  const copyToClipboard = () => {
    navigator.clipboard.writeText(contractAddress);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 2000);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const renderCastContent = (cast) => {
    if (cast.text) {
      return (
        <div className="flex flex-col gap-2">
          <p>{cast.text}</p>
          {cast.embeds?.[0]?.url && (
            <img 
              src={cast.embeds[0].url} 
              alt="Cast content" 
              className="rounded-lg max-h-48 object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.style.display = 'none';
              }}
            />
          )}
        </div>
      );
    } else if (cast.embeds?.[0]?.url) {
      return (
        <img 
          src={cast.embeds[0].url} 
          alt="Cast content" 
          className="rounded-lg max-h-48 object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.style.display = 'none';
          }}
        />
      );
    } else if (cast.embeds?.[0]?.castId) {
      return <span className="flex items-center gap-1"><Repeat size={16} /> Recast</span>;
    }
    return null;
  };

  useEffect(() => {
    const fetchPrice = async () => {
      setIsLoading(true);
      setPriceError(null);
      try {
        const response = await fetch(
          'https://api.geckoterminal.com/api/v2/networks/base/pools/0x3d9228f1847b07e6b2c8eaaf393d5a4db2dbedc2'
        );
        if (!response.ok) throw new Error('Failed to fetch price data');
        const data = await response.json();
        const currentPrice = data.data.attributes.base_token_price_usd;
        setPrice(currentPrice);
      } catch (error) {
        console.error('Error fetching price:', error);
        setPriceError('Failed to load price data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 30000); // Update every 30 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`min-h-screen font-rajdhani ${
      isDark 
        ? 'bg-gradient-to-b from-black via-zinc-900 to-black text-sky-300' 
        : 'bg-gradient-to-b from-gray-50 via-white to-gray-50 text-sky-800'
    }`}>
      {/* Theme Toggle */}
      <button
        onClick={() => setIsDark(!isDark)}
        className={`fixed top-4 right-4 p-3 rounded-lg z-50 transition-colors ${
          isDark 
            ? 'bg-zinc-900/50 text-sky-300 hover:bg-zinc-800' 
            : 'bg-white text-sky-800 hover:bg-gray-100 shadow-md'
        }`}
        aria-label={isDark ? 'Switch to light mode' : 'Switch to dark mode'}
      >
        {isDark ? <Sun size={24} /> : <Moon size={24} />}
      </button>

      {/* Hero Section */}
      <div className="w-full h-auto relative">
        <img 
          src="/banner.png" 
          alt="Banner" 
          className="w-full h-auto object-cover opacity-90"
        />
        <div className={`absolute inset-0 ${
          isDark 
            ? 'bg-gradient-to-b from-black/30 via-black/50 to-black' 
            : 'bg-gradient-to-b from-white/30 via-white/50 to-gray-50'
        }`}></div>
      </div>
      <div className="container mx-auto px-4 py-8">
        {/* Title Section */}
        <div className="text-center mb-16">
          <h1 className={`text-7xl font-bold mb-4 ${
            isDark ? 'text-sky-300' : 'text-sky-800'
          }`}>
            ghffb47yii2rteeyy10op
          </h1>

          <p className={`text-3xl mb-8 ${
            isDark ? 'text-sky-400/80' : 'text-sky-700'
          }`}>
            Yes, this is Dog?  I wrote the ticker.
          </p>

          {/* Contract Address */}
          <div className={`mt-4 flex items-center justify-center gap-2 mb-4 p-4 rounded-lg w-fit mx-auto border ${
            isDark 
              ? 'bg-zinc-900/80 border-sky-900/30' 
              : 'bg-white border-sky-200 shadow-md'
          }`}>
            <code className={isDark ? 'text-sky-300 text-sm font-mono' : 'text-sky-800 text-sm font-mono'}>
              {contractAddress}
            </code>
            <button 
              onClick={copyToClipboard}
              className={`p-2 rounded-lg transition-colors ${
                isDark 
                  ? 'text-sky-400 hover:bg-sky-900/20' 
                  : 'text-sky-700 hover:bg-sky-100'
              }`}
              aria-label="Copy contract address"
            >
              <Copy size={20} />
            </button>
          </div>

          {showCopied && (
            <div className={`rounded-lg p-3 w-fit mx-auto ${
              isDark 
                ? 'bg-sky-950/30 border border-sky-900/50 text-sky-300' 
                : 'bg-sky-100 border border-sky-200 text-sky-800'
            }`}>
              Contract address copied!
            </div>
          )}
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {[
            {
              title: '100% Safe',
              description: 'Contract verified and audited. Liquidity locked forever.'
            },
            {
              title: 'Community Driven',
              description: 'Fair launch with no team tokens. Power to the holders.'
            },
            {
              title: 'Zero Tax',
              description: 'No hidden fees or taxes. What you see is what you get.'
            }
          ].map((feature, index) => (
            <div key={index} className={`p-8 rounded-lg border transition-all ${
              isDark 
                ? 'bg-zinc-900/50 border-sky-900/20 hover:border-sky-800/30' 
                : 'bg-white border-sky-200 hover:border-sky-300 shadow-md'
            }`}>
              <h3 className={`text-2xl font-bold mb-4 ${
                isDark ? 'text-sky-300' : 'text-sky-800'
              }`}>{feature.title}</h3>
              <p className={isDark ? 'text-sky-400/80' : 'text-sky-700/80'}>
                {feature.description}
              </p>
            </div>
          ))}
        </div>

        {/* Current Price */}
        <div className={`text-center mb-16 p-6 rounded-lg w-fit mx-auto border ${
          isDark 
            ? 'bg-zinc-900/50 border-sky-900/20' 
            : 'bg-white border-sky-200 shadow-md'
        }`}>
          {isLoading ? (
            <p className={`text-2xl ${isDark ? 'text-sky-400/80' : 'text-sky-700/80'}`}>
              Loading price...
            </p>
          ) : priceError ? (
            <p className="text-2xl text-red-500">
              {priceError}
            </p>
          ) : (
            <p className={`text-2xl ${isDark ? 'text-sky-400/80' : 'text-sky-700/80'}`}>
              Current Price: <span className={`font-bold ${
                isDark ? 'text-sky-300' : 'text-sky-800'
              }`}>${parseFloat(price).toFixed(6)}</span>
            </p>
          )}
        </div>

        {/* Latest Casts Section */}
        <div className="mb-16">
          <h2 className={`text-3xl font-bold mb-6 text-center ${
            isDark ? 'text-sky-300' : 'text-sky-800'
          }`}>Latest Casts</h2>
          
          <div className={`rounded-lg border ${
            isDark 
              ? 'bg-zinc-900/50 border-sky-900/20' 
              : 'bg-white border-sky-200 shadow-md'
          }`}>
            {castsLoading ? (
              <div className="p-8 text-center">
                <p className={isDark ? 'text-sky-400' : 'text-sky-700'}>Loading casts...</p>
              </div>
            ) : castsError ? (
              <div className="p-8 text-center">
                <p className="text-red-500">Error loading casts. Please try again later.</p>
              </div>
            ) : casts.length === 0 ? (
              <div className="p-8 text-center">
                <p className={isDark ? 'text-sky-400' : 'text-sky-700'}>No casts found.</p>
              </div>
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className={isDark ? 'border-b border-sky-900/20' : 'border-b border-sky-200'}>
                      <th className="text-left p-4">Time</th>
                      <th className="text-left p-4">Content</th>
                      <th className="text-center p-4">Engagement</th>
                    </tr>
                  </thead>
                  <tbody>
                    {casts.slice(0, 10).map((cast, index) => (
                      <tr key={index} className={`${
                        isDark 
                          ? 'border-b border-sky-900/20 hover:bg-sky-900/5' 
                          : 'border-b border-sky-200 hover:bg-sky-50'
                      } transition-colors`}>
                        <td className="p-4 whitespace-nowrap">
                          {formatDate(cast.castedAtTimestamp)}
                        </td>
                        <td className="p-4 max-w-xl">
                          <a 
                            href={cast.url} 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            className={`hover:underline ${
                              isDark ? 'text-sky-400' : 'text-sky-700'
                            } block`}
                          >
                            <div className="flex flex-col gap-2">
                              {renderCastContent(cast)}
                            </div>
                          </a>
                        </td>
                        <td className="p-4">
                          <div className="flex items-center justify-center gap-4">
                            <span className="flex items-center gap-1">
                              <ThumbsUp size={16} className={isDark ? 'text-sky-400' : 'text-sky-700'} />
                              {cast.numberOfLikes}
                            </span>
                            <span className="flex items-center gap-1">
                              <Repeat size={16} className={isDark ? 'text-sky-400' : 'text-sky-700'} />
                              {cast.numberOfRecasts}
                            </span>
                            <span className="flex items-center gap-1">
                              <MessageSquare size={16} className={isDark ? 'text-sky-400' : 'text-sky-700'} />
                              {cast.numberOfReplies}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className={isDark ? 'bg-black/40' : 'bg-gray-50'}>
        <div className="container mx-auto text-center py-8">
          {/* Social Links */}
          <div className="flex justify-center gap-6 mb-4">
            {[
              {
                icon: 'D',
                url: 'https://dexscreener.com/base/0x3d9228f1847b07e6b2c8eaaf393d5a4db2dbedc2',
                label: 'View on Dexscreener'
              },
              {
                icon: <MessageCircle size={24} />,
                url: 'https://telegram.org',
                label: 'Join Telegram'
              },
              {
                icon: <ExternalLink size={24} />,
                url: 'https://etherscan.io/address/0x354d6890caA31a5E28B6059D46781F40880786a6',
                label: 'View on Etherscan'
              },
              {
                icon: 'W',
                url: 'https://warpcast.com/mk/0xb900eb09cc93add821c843c7e1d632aa26ff4fd6',
                label: 'Follow on Warpcast'
              }
            ].map((link, index) => (
              <a 
                key={index}
                href={link.url} 
                target="_blank" 
                rel="noopener noreferrer"
                aria-label={link.label}
                className={`p-4 rounded-lg transition-all border ${
                  isDark 
                    ? 'bg-zinc-900/50 border-sky-900/20 text-sky-400 hover:text-sky-300 hover:bg-sky-900/20' 
                    : 'bg-white border-sky-200 text-sky-700 hover:text-sky-600 hover:bg-sky-50 shadow-md'
                }`}
              >
                {typeof link.icon === 'string' ? (
                  <span className="font-bold text-xl">{link.icon}</span>
                ) : link.icon}
              </a>
            ))}
          </div>
          {/* Copyright */}
          <p className={isDark ? 'text-sky-400/60 text-sm' : 'text-sky-700/60 text-sm'}>
            &copy; {new Date().getFullYear()} ghffb47yii2rteeyy10op, all rights reserved.
          </p>
        </div>
      </footer>

      {/* Global styles for animations */}
      <style jsx global>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }

        .animate-fade-in {
          animation: fadeIn 0.5s ease-out;
        }

        @keyframes slideIn {
          from { 
            opacity: 0;
            transform: translateY(10px);
          }
          to { 
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-slide-in {
          animation: slideIn 0.3s ease-out;
        }

        * {
          transition-property: background-color, border-color, color, fill, stroke;
          transition-duration: 200ms;
          transition-timing-function: ease-in-out;
        }
      `}</style>
    </div>
  );
};

export default MemeTokenWebsite;